let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
let gamesDbName = 'games';
let groupsDbName = 'groups';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-foraeldremodespil-test.web.app';
	apiURL = 'https://cgl-foraeldremodespil-test.web.app/api/';
	if (env === 'demo') {
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
		gameUrl = 'cgl-foraeldremodespil-demo.web.app';
		apiURL = 'https://cgl-foraeldremodespil-demo.web.app/api/';
	}
}
if (env === 'production') {
	gameUrl = 'spil.faelles-skabet.dk';
	apiURL = 'https://cgl-foraeldremodespil-pro.web.app/api/';
}
 
const appConfig = {
	env: env,
	showDevTools: (env === 'development' || env === 'test'),
	gamesDbName,
	groupsDbName,
	apiURL: apiURL,
	gameUrl: gameUrl,
	cookieConsentCookieName: 'foraeldremodespil_cookie-consent-data',
	groupNameMinLength: 3,
	groupNameMaxLength: 15
};

export default appConfig;
